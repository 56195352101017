body {
  margin: 0;
  font-family: Circular Pro Book, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

html {
  /* custom cursor */
  cursor: url(cursor.png) 42 63, auto;

  /*colours*/
  color: #573c31;
  background: #92f4ff;

  /* fonts */
  font-size: 15pt;
  letter-spacing: 0.1em;
}

@font-face {
  font-family: 'Circular Pro Book';
  src: url('./fonts/CircularPro-Book.eot');
  src: url('./fonts/CircularPro-Book.eot?#iefix') format('embedded-opentype'),
      url('./fonts/CircularPro-Book.woff2') format('woff2'),
      url('./fonts/CircularPro-Book.woff') format('woff'),
      url('./fonts/CircularPro-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


a {
  color: #573c31;
  text-decoration: none;
}