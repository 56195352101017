.App{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container{
  width: 90vw;
  max-width: calc(1.77 * 70vmin);
}

