.TopLeft{
  position: fixed;
  top: 15px;
  left: 15px;
}

.BottomLeft{
  position: fixed;
  bottom: 15px;
  left: 15px;
}

.BottomRight{
  position: fixed;
  bottom: 15px;
  right: 15px;
}