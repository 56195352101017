.ImpressumOuter{
  width: 100vw;
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-top: 200px;
  margin-bottom: 200px;
}

.ImpressumInner{
  width: 90vw;
  max-width: calc(1.77 * 70vmin);
}

.ImpressumText{
  white-space: pre-line;
}